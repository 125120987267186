import React from 'react';
import IndustryDataTypeTemplate from '@components/IndustryDataType/IndustryDataTypeTemplate';
import { dataTypes, industries } from '@constants/industriesAndDatatypes';
import BackgroundImage from './assets/Oil_&_Gas_Cover.jpg';
import metaImage from './assets/meta.jpg';

const OilAndGasPage: React.FC = () => {
  return (
    <IndustryDataTypeTemplate
      pageName="industry-oil-and-gas"
      helmet={{
        title: 'TileDB for Oil and Gas',
        description: 'Use TileDB for scalable real-time processing, monitoring and analytics for all oil and gas data.',
        shareImage: {
          url: metaImage,
          width: 1200,
          height: 627,
        },
      }}
      header="Oil and Gas"
      title="Scalable real-time processing, monitoring and analytics for all oil and gas data"
      heroImage={BackgroundImage}
      icon={industries.oilAndGas.icon}
      overview={
        <>
          <p>
            Data analytics on the cloud is unleashing new efficiencies and cost-savings in the capital-intensive oil and gas industry across
            all tiers of upstream, midstream and downstream operations. Inventory data, geospatial, seismic, geochemical, geophysical data,
            emissions, sensor and financial pricing plus the metadata - the list goes on and there’s lots of data all over. Can you bring it
            all together and make sense of it - quickly and easily?
          </p>
          <p>
            TileDB is uniquely suited for the oil and gas industry, storing a variety of sources in a single database. It is the one-stop
            shop for your entire organization where data, models, dashboard and domain-specific algorithms for exploration, demand
            forecasting and price optimization are instantly accessible without specialized infrastructure.
          </p>
        </>
      }
      relatedItems={[dataTypes.lidar, dataTypes.timeSeries, dataTypes.hyperspectral]}
    />
  );
};

export default OilAndGasPage;
